.ltr {
  /* background-color: #07bc0c; */
  direction: ltr;
  border-radius: 100%;
  color: #202020;
  font-size: 13px;
  /* height: 45px; */
  left: 230px;
  position: absolute;
  text-align: center;
  top: 25px;
  width: 45px;
  z-index: 100;
  font-weight: 900;
}

.rtl {
  /* background-color: #07bc0c; */
  direction: rtl;
  border-radius: 100%;
  color: #202020;
  font-size: 13px;
  /* height: 45px; */
  right: 230px;
  position: absolute;
  text-align: center;
  top: 25px;
  width: 45px;
  z-index: 100;
  font-weight: 900;
}
