.copilot-chat-message {
    display: flex;
    margin-left: 20px;
    gap: .75%;
}

.copilot-chat-message-icon {
    margin-top: 14px;
}

.copilot-main-chat-panel {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.copilot-chat-panel {
    height: 100px;
    background: inherit;
}

.copilot-chat-list {
    margin-bottom: 50px;
}

.copilot-empty-screen {
    padding-top: 10px;
    text-align: left;
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
}

.message-container {
    width: 100%;
    box-sizing: border-box;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}