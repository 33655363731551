.projectHeader {
    flex-wrap: wrap;
}

.buttonBox {
    margin-left: 0.3em;
    margin-bottom: 1em;
}

.projectNameWrapper {
    flex-grow: 3;
}

.projectName {
    word-break: break-all;  
}



