.copilot-prompt-form {
    margin: auto;
    text-align: center;
}

.copilot-prompt-textarea {
    border: none;
    border-bottom: 1px solid #ccc;
    width: 86%;
    resize: none;
    font-size: 16px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    min-height: 16px;
    outline: none;
    padding: 8px;
}

.classic {
    display: flex;
    position: fixed;
    align-items: center;
    bottom: 0;
    width: 100%;
    margin-bottom: 20px;
}

.for_feature_request > textarea {
    width: 100% !important;
}

.for_feature_request {
    flex: 1;
    display: flex;
    align-items: baseline;
    align-content: baseline;
}

.for_dialog {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
}