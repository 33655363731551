/* Dies blendet alle Tasks aus, wenn 'only-not-started' aktiviert ist, 
   außer diejenigen, die den Status 'NotStarted' haben. */
.task-list-wrapper.only-not-started .status-in-progress,
.task-list-wrapper.only-not-started .status-completed
/* Füge hier weitere Statusklassen hinzu, die ausgeblendet werden sollen, wenn 'checked' aktiviert ist */ {
  display: none;
}

/* Behalte die Darstellung für den Status 'NotStarted', auch wenn 'checked' aktiviert ist */
.task-list-wrapper.only-not-started .status-notstarted {
  display: block; /* Oder 'flex', 'inline', je nachdem, welches Display-Verhalten du bevorzugst */
}

.status-completed {
  font-weight: bold;
  font-style: italic;
}

[data-testid="task-list"] .MuiList-root {
  padding-top: 0;
  padding-bottom: 0;
}
